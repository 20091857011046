
(function( $ ) {

    $.fn.fieldsTabs = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            const jqContainer = $(this);
            const checkboxContainers = jqContainer.find('.field-tab-heading label.checkcontainer');
            if (checkboxContainers.length <= 0) {
                console.error('fieldsTabs: no checkboxContainers found by query ".field-tab-heading label.checkcontainer" in ', this);
                return false;
            }
            const allConnectedTabsContainer = jqContainer.find('.fields-tabs');
            const allConnectedTabs = jqContainer.find('.fields-tab');
            if (allConnectedTabsContainer.length <= 0) {
                console.error('fieldsTabs: no connectedTabs found by query ".fields-tab" in ', this);
                return false;
            }
            if (checkboxContainers.length <= 0) {
                console.error('fieldsTabs: no connectedTabs found by query ".fields-tab" in ', this);
                return false;
            }
            //reset tabs
            allConnectedTabs.removeClass('current');
            allConnectedTabs.height(0);
            allConnectedTabsContainer.height(0);
            //
            checkboxContainers.each(function() {
                const jqCheckboxContainer = $(this);
                const checboxInput = jqCheckboxContainer.find('input').first();
                if (!checboxInput) {
                    console.error('fieldsTabs: checboxInput not found in ', this);
                    return false;
                }
                const connectedTab = jqContainer.find('#' + jqCheckboxContainer.data('connectedTab')).first();
                const connectedTabHolder = connectedTab.find('.fields-tab-holder');
                if (!connectedTab) {
                    console.error('fieldsTabs: connectedTab not found by id:', jqCheckboxContainer.data('connectedTab'));
                    return false;
                }
                if (!connectedTabHolder) {
                    console.error('fieldsTabs: connectedTabHolder not found by query ".fields-tab-holder" in ', connectedTab);
                    return false;
                }
                const connectedTabInnerHeight = connectedTabHolder.innerHeight();
                if (checboxInput.is(':checked')) {
                    connectedTab.height(connectedTabInnerHeight);
                    allConnectedTabsContainer.height(connectedTabInnerHeight);
                    connectedTab.addClass('current');
                }
                checboxInput.change(function() {
                    allConnectedTabs.removeClass('current');
                    allConnectedTabs.height(0);
                    allConnectedTabsContainer.height(0);
                    if (this.checked) {
                        connectedTab.height(connectedTabInnerHeight);
                        allConnectedTabsContainer.height(connectedTabInnerHeight);
                        connectedTab.addClass('current');
                    }
                })
            })
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.fields-tab-active').fieldsTabs();
});
